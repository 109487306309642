<div class="mat-elevation-z8">
    <mat-toolbar fxLayout="row" fxLayoutGap="10px">
        <mat-form-field style="width:25em">
        <mat-label>予約フォーム選択</mat-label>
            <mat-select required (selectionChange)="changeFrm($event.value)" [(ngModel)]="admsrv.wshid">
                <mat-option *ngFor="let frm of admsrv.forms" [value]="frm.wid">
                {{ frm.date + frm.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <p>の参加者を表示</p> 
        <button mat-flat-button color="accent" (click)="sendMail()">以下の会員宛に一斉メール送信</button>
        <a mat-flat-button target="_blank" href="https://www.tokaido.tokyo/user_data/download/SalonManual/owner/14.pdf"><mat-icon>help</mat-icon>PDFマニュアル</a>
    </mat-toolbar>
    <table mat-table [dataSource]="dataSource" matSort class="full-width-table" aria-label="Elements">
        <ng-container matColumnDef="mail">
            <th mat-header-cell *matHeaderCellDef>メールアドレス</th>
            <td mat-cell *matCellDef="let row">{{row.mail}}</td>
        </ng-container>  
        <ng-container matColumnDef="memid">
            <th mat-header-cell *matHeaderCellDef>予約者番号</th>
            <td mat-cell *matCellDef="let row">{{row.memid}}</td>
        </ng-container> 
        <ng-container matColumnDef="eda">
            <th mat-header-cell *matHeaderCellDef>枝番</th>
            <td mat-cell *matCellDef="let row">{{row.eda}}</td>
        </ng-container> 
        <ng-container matColumnDef="sei">
            <th mat-header-cell *matHeaderCellDef>参加者姓</th>
            <td mat-cell *matCellDef="let row">{{row.sei}}</td>
        </ng-container> 
        <ng-container matColumnDef="mei">
            <th mat-header-cell *matHeaderCellDef>参加者名</th>
            <td mat-cell *matCellDef="let row">{{row.mei}}</td>
        </ng-container> 
        <ng-container matColumnDef="birth">
            <th mat-header-cell *matHeaderCellDef>生年月日</th>
            <td mat-cell *matCellDef="let row">{{row.birth}}</td>
        </ng-container> 
        <ng-container matColumnDef="class">
            <th mat-header-cell *matHeaderCellDef>クラス</th>
            <td mat-cell *matCellDef="let row">{{row.class}}</td>
        </ng-container>         <ng-container matColumnDef="tstmp">
            <th mat-header-cell *matHeaderCellDef>タイムスタンプ</th>
            <td mat-cell *matCellDef="let row">{{row.tstmp}}</td>
          </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginators
        [length]="dataSource?.data.length"
        [pageIndex]="0"
        [pageSize]="15"
        [pageSizeOptions]="[15, 30, 50, 100]">
    </mat-paginator>
  </div>