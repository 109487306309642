<div class="mat-elevation-z8">    
    <mat-toolbar fxLayout="column">
      <mat-toolbar-row style="height: 150px;">
        <qr-code [value]="admsrv.link2" [size]="130"></qr-code>
        <p style="white-space:normal; min-width:200px;">{{admsrv.link2}}</p>
      </mat-toolbar-row>
      <mat-toolbar-row fxLayoutGap="5px">
        <mat-form-field>
          <input matInput placeholder="カレンダー名入力" [(ngModel)]="name">
        </mat-form-field>      
        <button mat-flat-button color="accent" (click)="insCal()">のカレンダーを追加</button>
        <button mat-flat-button (click)="reRead()">表示更新</button>
        <a mat-flat-button target="_blank" href="https://www.tokaido.tokyo/user_data/download/SalonManual/owner/13.pdf"><mat-icon>help</mat-icon>PDFマニュアル</a>  
      </mat-toolbar-row>
    </mat-toolbar>
    <table mat-table [dataSource]="dataSource" class="full-width-table" aria-label="Elements">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>カレンダー名</th>
          <td mat-cell *matCellDef="let row">{{row.name}}</td>
        </ng-container>
        <ng-container matColumnDef="url">
          <th mat-header-cell *matHeaderCellDef fxFlex="400px">カレンダーリンクアドレス</th>
          <td mat-cell *matCellDef="let row" fxFlex="400px" style="font-size: 55%;">{{row.url}}</td>
        </ng-container>
        <ng-container matColumnDef="qr">
            <th mat-header-cell *matHeaderCellDef fxFlex="150px">QRコード</th>
            <!-- <td mat-cell *matCellDef="let row">{{row.url}}</td> -->
            <td mat-cell *matCellDef="let row" fxFlex="150px"><qr-code [value]="row.url" [size]="130" [style.display]="row.del=='削除済' ? 'none' : 'block'"></qr-code></td>
        </ng-container>
        <ng-container matColumnDef="del">
          <th mat-header-cell *matHeaderCellDef>削除</th>
          <td mat-cell *matCellDef="let row">{{row.del}}</td>
        </ng-container>
        <ng-container matColumnDef="act1">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let row; let i = index" class="i_num">
            <button mat-raised-button color="warn" focusable="false" (click)="deleteRow(i)" class="tbl_bt" [style.display]="row.del=='削除済' ? 'none' : 'block'">
              <mat-icon>delete_forever</mat-icon>Del
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 150px;" [ngStyle]="{'background-color': setBgcolor(row.del)}"></tr>
      </table>
    </div>