<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
  <h2>オンラインサロン予約システム アカウント登録</h2>
  <a mat-flat-button target="_blank" href="https://www.tokaido.tokyo/user_data/download/SalonManual/owner/00.pdf"><mat-icon>help</mat-icon>PDFマニュアル</a>
</mat-toolbar>
<mat-card style="max-width: 800px;margin: 0 auto;">
  <mat-horizontal-stepper #stepper [linear]="true"> 
    <mat-step [stepControl]="firstFormGroup" label="基本情報入力">   
      <form [formGroup]="firstFormGroup" class="container" ngDefaultControl ngNativeValidate fxLayout="column">
        <!-- <mat-form-field>
            <mat-label>予約システムアカウントID</mat-label>
            <input matInput numberInput [value]="ownsrv.owner.dojoid" disabled>
        </mat-form-field> -->
        <mat-form-field>
            <mat-label>団体名or道場名</mat-label>
            <input matInput formControlName="nam" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>代表者姓</mat-label>
            <input matInput formControlName="sei" required>
        </mat-form-field>
        <mat-form-field >
            <mat-label>代表者名</mat-label>
            <input matInput formControlName="mei" required>
        </mat-form-field>
        <mat-form-field >
            <mat-label>連絡先電話番号</mat-label>
            <input matInput formControlName="tel" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>メール</mat-label>
            <input matInput [value]="ownsrv.owner.mail" disabled>
        </mat-form-field>
        <div>
          <button mat-button mat-stroked-button color="primary" matStepperNext>次へ</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" label="住所入力">
      <form [formGroup]="secondFormGroup" class="example-form container" ngNativeValidate ybAddress (submit)="false" [ybAutocompleteMode]="'off'" #address="ybAddress" fxLayout="column">
        <mat-form-field>
          <mat-label>郵便番号</mat-label>
          <input matInput formControlName="zip" ybPostalCode required>
          <button mat-button matSuffix mat-stroked-button aria-label="get" (click)="address.complete()">
            住所補完
          </button>
        </mat-form-field>
        <mat-form-field>
          <mat-label>都道府県</mat-label>
          <input matInput formControlName="reg" ybRegion required>
        </mat-form-field> 
        <mat-form-field>
          <mat-label>市区町村</mat-label>
          <input matInput formControlName="loc" ybLocality required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>町名・番地</mat-label>
          <input matInput formControlName="str" ybStreet required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>マンション・ビル名</mat-label>
          <input matInput formControlName="ext" ybExtended>
        </mat-form-field>
        <mat-form-field>
          <mat-label>ホームページアドレス</mat-label>
          <input matInput formControlName="url">
        </mat-form-field>
        <div>
          <button mat-button mat-stroked-button matStepperPrevious>戻る</button>
          <button mat-button mat-stroked-button color="primary" matStepperNext>次へ</button>
        </div>
      </form> 
    </mat-step>   
    <mat-step label="カレンダー・申込">
      <form [formGroup]="thirdFormGroup">
        <fieldset>
          <legend>カレンダー名を入力してください(追加できます)</legend>
          <div formArrayName="options">
            <!-- for文を回して、表示 -->
            <div *ngFor="let option of options.controls; let i = index">
              <!-- 中にあるFromGroupの名前はindex名になるのがポイント -->
              <div [formGroupName]="i">
                <mat-form-field>
                  <mat-label>カレンダー{{i + 1}}</mat-label>
                  <input matInput formControlName="calender" />
                  <button mat-button matSuffix mat-stroked-button (click)="removeOptionForm(i)" tabindex="-1">削除</button>
                </mat-form-field>
              </div>
              <!-- 削除するときはindexが必要になるので引数で渡す -->
            </div>
          </div>
          <button mat-button mat-stroked-button (click)="addOptionForm()">カレンダー追加</button>  
        </fieldset>
      </form>
      <br>
      <div *ngIf="!this.ownsrv.owner.dojoid">
        <button mat-button mat-stroked-button matStepperPrevious>戻る</button>
        <button mat-button mat-stroked-button (click)="stepper.reset()">入力リセット</button>
      </div>
      <br>
      <div *ngIf="this.ownsrv.owner.dojoid">
        <p>ご登録ありがとうございます。10分以内に届く完了メールをお待ちください。</p>
      </div>
      <div *ngIf="!this.ownsrv.owner.dojoid">
        <button mat-flat-button color="accent" (click)="ins_owner(false)">基本プラン申し込み</button>
        <p>※決済サービスを利用した場合、決済手数料がかかりますが、その他の機能は無料でご使用いただけます。</p>
        <button mat-flat-button color="warn" (click)="ins_owner(true)">基本プラン＋名簿作成管理申し込み</button>
        <p>※基本プランと併せて有償の名簿作成管理プランを申し込まれる方は、このボタンよりお申込みください。</p>
      </div>
    </mat-step> 
  </mat-horizontal-stepper>      
</mat-card>