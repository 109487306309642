<div class="mat-elevation-z8">    
    <table mat-table [dataSource]="dataSource" class="full-width-table" aria-label="Elements">
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef>送信日時</th>
          <td mat-cell *matCellDef="let row">{{row.created_at | date:'yy/MM/dd HH:mm'}}</td>
        </ng-container>  
        <ng-container matColumnDef="formnm">
            <th mat-header-cell *matHeaderCellDef>予約フォーム名</th>
            <td mat-cell *matCellDef="let row">{{row.formnm}}</td>
        </ng-container>
        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef>件名</th>
          <td mat-cell *matCellDef="let row">{{row.subject}}</td>
        </ng-container>
        <!-- <ng-container matColumnDef="sendto">
            <th mat-header-cell *matHeaderCellDef>送信先</th>
            <td mat-cell *matCellDef="let row">{{row.sendto}}</td>
        </ng-container> -->
        <ng-container matColumnDef="body">
            <th mat-header-cell *matHeaderCellDef>本文</th>
            <td mat-cell *matCellDef="let row">{{row.body}}</td>
        </ng-container>
        <ng-container matColumnDef="fromnm">
            <th mat-header-cell *matHeaderCellDef>送信元名</th>
            <td mat-cell *matCellDef="let row">{{row.fromnm}}</td>
        </ng-container>   
        <ng-container matColumnDef="from">
            <th mat-header-cell *matHeaderCellDef>返信先</th>
            <td mat-cell *matCellDef="let row">{{row.from}}</td>
        </ng-container>             
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator #paginators
        [length]="dataSource?.data.length"
        [pageIndex]="0"
        [pageSize]="15"
        [pageSizeOptions]="[15, 30, 50, 100]">
      </mat-paginator>
    </div>