<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
  <h1>オンラインサロン予約システム</h1> 
  <a mat-flat-button target="_blank" href="https://www.tokaido.tokyo/user_data/download/SalonManual/owner/00.pdf"><mat-icon>help</mat-icon>PDFマニュアル</a>
</mat-toolbar>
<mat-card>
  <p> 本サービスはGoogleアカウントを使用します。<br>Googleアカウントがない方は、新規アカウントを作成してください。</p>
  <p><a href="https://accounts.google.com/signup" mat-flat-button target="blank" color="warn">googleアカウント作成(別window)</a><br></p>
  <p> Googleアカウントをお持ちの方は、ログインしてください。<br>別のGoogleアカウントで登録する場合も下記ボタンを押してください。</p>
  <button style="border:0;background:none;" (click)="login()">
　　  <img src="assets/btn_google_signin_dark_normal_web.png" />
  </button> 
  <div *ngIf="claim">
    <div *ngIf="!ownsrv.flgEx">
      <p> Googleアカウントにログイン後、下記ボタンより、当サービスのアカウントを登録してください。<br></p>
      <button mat-flat-button color="accent" (click)="toRegist()">Googleアカウント {{email}} で予約管理アカウント登録</button>          
    </div>
    <div *ngIf="ownsrv.flgEx">
      <p> 下記ボタンより、管理画面へ移動します。<br></p>
      <button mat-flat-button color="accent" (click)="toAdmin()">{{ownsrv.owner.dojoname}} 様の予約管理画面へ</button>
    </div>  
  </div>
</mat-card>
