<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
  <h2>{{ownsrv.owner.dojoname}} 様 オンラインサロン予約システム 管理画面</h2>
  <a mat-flat-button target="_blank" href="https://www.tokaido.tokyo/user_data/download/SalonManual/owner/10.pdf"><mat-icon>help</mat-icon>PDFマニュアル</a>
</mat-toolbar>
<mat-card>
  <mat-tab-group [(selectedIndex)]="TabIndex" [backgroundColor]="'primary'" >
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">dynamic_form</mat-icon>
        予約フォーム
      </ng-template>
      <mat-card-content>
        <app-tblform (action)="readGdrive()" (action2)="goList()"></app-tblform>
      </mat-card-content>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">insert_link</mat-icon>
        予約フォームパターン
      </ng-template>
      <mat-card-content>
        <app-tblfpat></app-tblfpat>
      </mat-card-content>
    </mat-tab> 
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">event</mat-icon>
        カレンダー管理
      </ng-template>
      <mat-card-content>
        <app-tblcal (action)="readGdrive()"></app-tblcal>
      </mat-card-content>
    </mat-tab> 
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">list</mat-icon>
        予約管理
      </ng-template>
      <mat-card-content>
        <app-tblres></app-tblres>
      </mat-card-content>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">mail</mat-icon>
        メール送信履歴
      </ng-template>
      <mat-card-content>  
        <app-tblmail></app-tblmail>
      </mat-card-content>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">payment</mat-icon>
        月謝管理
      </ng-template>
      <mat-card-content>  
        決済契約後、導入予定
      </mat-card-content>
    </mat-tab>
    <mat-tab *ngIf="ownsrv.owner.plan">
      <ng-template mat-tab-label>
          <mat-icon class="tab-icon">people</mat-icon>
          会員管理
      </ng-template>
      <mat-card-content>  
        作成中
      </mat-card-content>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
          <mat-icon class="tab-icon">account_box</mat-icon>
          登録情報変更
      </ng-template>
      <mat-card-content class="container">  
        <div class="mat-elevation-z8">
          <mat-toolbar fxLayoutGap="10px">
            <button mat-flat-button color="accent" (click)="updOwner()">登録情報更新</button >
            <div *ngIf="flg">
              <p>プラン変更を受け付けました</p>
            </div>
            <div *ngIf="!flg">
              <button mat-flat-button color="accent" (click)="regPlan()" *ngIf="!ownsrv.owner.plan">名簿管理プラン申込</button >
              <button mat-flat-button color="accent" (click)="canPlan()" *ngIf="ownsrv.owner.plan">名簿管理プラン解約</button >
            </div>
          </mat-toolbar>
          <form [formGroup]="frmgrp" class="container" ngNativeValidate ybAddress (submit)="false" [ybAutocompleteMode]="'off'" #address="ybAddress">
            <div fxLayout="row wrap" fxLayoutGap="7px">
              <mat-form-field style="width: 20em;">
                <mat-label>団体名or道場名</mat-label>
                <input matInput formControlName="dojoname" required>
              </mat-form-field>
              <mat-form-field>
                  <mat-label>代表者姓</mat-label>
                  <input matInput formControlName="sei" required>
              </mat-form-field>
              <mat-form-field >
                  <mat-label>代表者名</mat-label>
                  <input matInput formControlName="mei" required>
              </mat-form-field>
              <mat-form-field >
                  <mat-label>連絡先電話番号</mat-label>
                  <input matInput formControlName="tel" required>
              </mat-form-field>
              <mat-checkbox [checked]="ownsrv.owner.plan" disabled>
                名簿管理プラン申込済
              </mat-checkbox>            
            </div>
            <div fxLayout="row wrap" fxLayoutGap="7px">
            <mat-form-field>
              <mat-label>郵便番号</mat-label>
              <input matInput formControlName="zip" ybPostalCode required>
              <button mat-button matSuffix mat-stroked-button aria-label="get" (click)="address.complete()">
                住所補完
              </button>
            </mat-form-field>
            <mat-form-field>
              <mat-label>都道府県</mat-label>
              <input matInput formControlName="region" ybRegion required>
            </mat-form-field> 
            <mat-form-field>
              <mat-label>市区町村</mat-label>
              <input matInput formControlName="local" ybLocality required>
            </mat-form-field>
            <mat-form-field>
              <mat-label>町名・番地</mat-label>
              <input matInput formControlName="street" ybStreet required>
            </mat-form-field>
            <mat-form-field>
              <mat-label>マンション・ビル名</mat-label>
              <input matInput formControlName="extend" ybExtended>
            </mat-form-field>
            <mat-form-field style="width: 20em;">
              <mat-label>ホームページアドレス</mat-label>
              <input matInput formControlName="url">
            </mat-form-field>
          </div>
          </form>
        </div>
      </mat-card-content>
    </mat-tab>    
  </mat-tab-group>
</mat-card>